import {vars} from "../../vars.js";

export const burger = ()=> {
    const burger = document.querySelector('.header__burger');
    const burgerClose = document.querySelector('.mobile-menu__close');

    burger?.addEventListener('click',()=> {
        vars.mobileMenu?.classList.add('open');
        vars.body?.classList.add('overflow-hidden')
    })
    burgerClose?.addEventListener('click',()=> {
        vars.mobileMenu?.classList.remove('open');
        vars.body?.classList.remove('overflow-hidden')
    })
}