export const servicesSlider = ()=> {
    $('.services-slider__slick').slick({
        slidesToShow: 1,
        infinite: false,
        speed: 300,
        mobileFirst: true,
        prevArrow: $('.services-slider-prev'),
        nextArrow: $('.services-slider-next'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    })

}