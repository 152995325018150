export const imageParallax = ()=> {
    if (document.querySelector('.image-parallax')) {

        // let mediaImage = gsap.matchMedia();
        // mediaImage.add("(min-width: 1023px)", () => {
            gsap.utils.toArray('.image-parallax').forEach(item => {
                // gsap.to(item.querySelector('img'), {
                //     scrollTrigger: {
                //         trigger: item,
                //         // start: '-200px bottom',
                //         scrub: true,
                //         pin: false,
                //     },
                //     yPercent: 40,
                //     ease: 'none',
                //
                // })
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        scrub: true,
                        pin: false,
                    }
                });

                tl.fromTo(item.querySelector('img'), {
                    yPercent: -20,
                    ease: 'none'
                }, {
                    yPercent: 20,
                    ease: 'none'
                });
            })
        // })
    }
}