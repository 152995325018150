import {smoothScrollTo} from "../smooth-scroll.js";
import {vars} from "../../vars.js";

export const hero = ()=> {
    const btnDown = document.querySelectorAll('.hero__btn-down');

    btnDown?.forEach(btn=> {
        btn?.addEventListener('click',()=> {
            const headerHeight = vars.header.getBoundingClientRect().height

            const currentSection = btn.closest('section')
            const nextSection = currentSection.nextElementSibling

            if (nextSection?.tagName === currentSection?.tagName) {
                const itemOffset = nextSection?.getBoundingClientRect().top - headerHeight
                smoothScrollTo(itemOffset,2)
            }
        })
    })
}