import {vars} from "../../vars.js";

export const resultSection = ()=> {
    const resultContainer = document.querySelector('.result__wrapper'),
          resultAfterWrapper = document.querySelector('.result__after-image'),
          resultAfterImage = resultAfterWrapper?.querySelector('img'),
          handle = document.querySelector('.result__drag');

    if (resultContainer) {

        if (resultAfterImage) {
            resultAfterImage.style.width = `${resultContainer.offsetWidth}px`
        }

        addEventListener('resize',()=> {
            setTimeout(()=> {
                if (resultAfterImage) {
                    resultAfterImage.style.width = `${resultContainer.offsetWidth}px`
                }
            },500)
        })

        let isMouseDown = false;

        document.addEventListener('mousedown', (event) => {
            if (event.target === handle) {
                isMouseDown = true;
            }
        });

        document.addEventListener('mouseup', (event) => {
            isMouseDown = false;
        });

        document.addEventListener('mousemove', (event) => {
            if (isMouseDown) {
                let cursorPosition = event.clientX - (window.innerWidth - resultContainer.offsetWidth) / 2
                const percent = (cursorPosition * 100) / resultContainer.offsetWidth
                const handleWidthPercent = ((handle.offsetWidth / 2) * 100) / resultContainer.offsetWidth
                if (percent >= 0 && percent <= 100) {
                    moveDivisor(percent)
                }
                if (percent <= 0 - handleWidthPercent || percent >= 100 + handleWidthPercent) {
                    isMouseDown = false;
                }
            }
        });

        handle?.addEventListener('touchstart', () => {
            isMouseDown = true;
            vars.body.classList.add('overflow-hidden')
        });

        handle?.addEventListener('touchend', () => {
            isMouseDown = false;
            vars.body.classList.remove('overflow-hidden')
        });

        document.addEventListener('touchmove', (event) => {
            if (isMouseDown) {
                const touch = event.touches[0];
                let cursorPosition = touch.clientX - (window.innerWidth - resultContainer.offsetWidth) / 2
                const percent = (cursorPosition * 100) / resultContainer.offsetWidth
                const handleWidthPercent = ((handle.offsetWidth / 2) * 100) / resultContainer.offsetWidth
                if (percent >= 0 && percent <= 100) {
                    moveDivisor(percent)
                }
                if (percent <= 0 - handleWidthPercent || percent >= 100 + handleWidthPercent) {
                    isMouseDown = false;
                }
            }
        });

        function moveDivisor(percent) {
            handle.style.left = percent+"%";
            resultAfterWrapper.style.width = percent+"%";
        }

    }
}