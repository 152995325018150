import {generalSliders} from "./modules/sliders/general.js";
import {generalHeader} from "./modules/header/generalHeader.js";
import {generalSections} from "./modules/sections/general.js";
import {anchorScroll} from "./modules/anchor-scroll.js";
import {generalGsap} from "./modules/gsap-amination/generalGsap.js";

document.addEventListener("DOMContentLoaded", function (event) {
    generalSliders();

    generalHeader();

    generalSections();

    anchorScroll();

    generalGsap();

});
