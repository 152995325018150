import {productSlider} from "./productSlider.js";
import {gallerySlider} from "./gallerySlider.js";
import {testimonialsSlider} from "./testimonialsSlider.js";
import {instagramSlider} from "./instagram.js";
import {certificatesSlider} from "./certificates.js";
import {servicesSlider} from "./servicesSlider.js";

export const generalSliders = ()=> {
    productSlider();

    gallerySlider();

    testimonialsSlider();

    instagramSlider();

    certificatesSlider();

    servicesSlider();
}