export const gallerySlider = ()=> {

    let targetFlag = false;

    $('.gallery__slider')
        .on('init', function(event, slick, direction){
            setTimeout(()=> {
                checkScrollPosition()
            })
        })
        .slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            mobileFirst: true,
            speed: 500,
            dots: true,
            fade: true,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 2500,
            pauseOnHover: false,
            swipe: false,
            pauseOnFocus: false,
            touchMove: false,
         })


    window.addEventListener('scroll',()=> {
        checkScrollPosition()
    })

    function checkScrollPosition () {
        const gallerySection = document.querySelector('.contact')
        const gallerySectionOffsetTop= gallerySection?.getBoundingClientRect().top + window.scrollY

        if (window.scrollY + (window.innerHeight / 2) >= gallerySectionOffsetTop && window.scrollY < gallerySectionOffsetTop + gallerySection.offsetHeight - (window.innerHeight / 2)) {
            if (!targetFlag) {
                $('.gallery__slider').slick('slickPlay');
                targetFlag = true
            }
        } else {
            if (targetFlag) {
                $('.gallery__slider').slick('slickPause');
                targetFlag = false
            }
        }
    }
}