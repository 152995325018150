import {smoothScrollTo} from "./smooth-scroll.js";
import {vars} from "../vars.js";

export const anchorScroll = ()=> {
    const anchorBtns = document.querySelectorAll('.anchor-scroll-js')
    const anchorMenusBtns = document.querySelectorAll('.anchor-scroll-menu-js')

    if (window.location.hash) {

        smoothScrollTo(0,2)
        setTimeout(()=> {
            try {
                const headerHeight = vars.header.getBoundingClientRect().height
                const hashElement = document.querySelector(window.location.hash)
                const elementOffset = hashElement?.getBoundingClientRect().top - headerHeight
                if (elementOffset < 0) {
                    smoothScrollTo(0,2)
                } else {
                    // const sideSpace = ((window.innerHeight - headerHeight) - hashElement.clientHeight) / 2
                    if ((window.innerHeight - headerHeight) < hashElement.clientHeight) {
                        const itemOffset = hashElement?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    } else {
                        const itemOffset = hashElement?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    }
                }
                history.pushState("", "", `${location.pathname}${location.search}`);
            } catch (error) {
            }
        },1000)
    }

    anchorBtns?.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            try {
                const headerHeight = vars.header.getBoundingClientRect().height
                const itemHref = item.getAttribute('href')

                const itemTarget = itemHref.includes(window.location.origin + window.location.pathname) ? document.querySelector(itemHref.replace(window.location.origin + window.location.pathname,'')) : document.querySelector(itemHref)
                if (itemTarget == null) {
                    window.location.href = window.location.origin + '/'+itemHref
                } else {
                    // const sideSpace = ((window.innerHeight - headerHeight) - itemTarget.clientHeight) / 2;
                    if ((window.innerHeight - headerHeight) < itemTarget.clientHeight) {
                        const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    } else {
                        const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    }
                }
                e.preventDefault()

            } catch (error) {
            }
        })
    })
    anchorMenusBtns?.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            try {
                const headerHeight = vars.header.getBoundingClientRect().height
                const itemLink = item.querySelector('a')
                const itemHref = itemLink?.getAttribute('href')
                const itemTarget = itemHref.includes(window.location.origin + window.location.pathname) ? document.querySelector(itemHref.replace(window.location.origin + window.location.pathname,'')) : document.querySelector(itemHref)
                //close mobile
                vars.body?.classList.remove('overflow-hidden')
                vars.header?.classList.remove('menu-opened')
                vars.mobileMenu?.classList.remove('open')

                //close mobile

                if (itemTarget == null) {
                    window.location.href = window.location.origin + '/'+itemHref
                } else {
                    // const sideSpace = ((window.innerHeight - headerHeight) - itemTarget.clientHeight) / 2
                    if ((window.innerHeight - headerHeight) < itemTarget.clientHeight) {
                        const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    } else {
                        const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                        smoothScrollTo(itemOffset,2)
                    }
                }
                e.preventDefault()

            } catch (error) {
                // console.log(error)
            }
        })
    })

}