export const instagramSlider = ()=> {
    $('.instagram__slider').slick({
        slidesToShow: 3.31,
        slidesToScroll: 1,
        infinite: false,
        // mobileFirst: true,
        speed: 300,
        prevArrow: $('.instagram__slider-prev'),
        nextArrow: $('.instagram__slider-next'),
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    prevArrow: $('.instagram__slider-prev-mob'),
                    nextArrow: $('.instagram__slider-next-mob'),
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    prevArrow: $('.instagram__slider-prev-mob'),
                    nextArrow: $('.instagram__slider-next-mob'),
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1.175,
                    slidesToScroll: 1,
                    prevArrow: $('.instagram__slider-prev-mob'),
                    nextArrow: $('.instagram__slider-next-mob'),
                }
            },

        ]
    })
}